@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'sansationbold';
  src: url('assets/font/Sansation-Bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  /* margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  max-width: 1500px;
  box-sizing: border-box; */
}

code {
  font-family:sansationbold, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.anvix-font {
  font-family:sansationbold;
}

.bottom-footer {

}